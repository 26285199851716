import { useApesStorageApi } from "@/api/useApesStorageApi";
import { ModalName } from "@/components/Modal/modal-name";
import { useModal } from "@/composables/useModal";
import { Config } from "@/config";
import { burnTeens } from "@/functions/burnTeens";
import { mintGodApe } from "@/functions/god-ape/mint-god-ape";
import { captureError } from "@/helpers/captureError";
import { FormulasIngredientType } from "@/helpers/formulas/formulas-ingredient-types";
import { signMessage } from "@/helpers/sign-message";
import { useGodApeQuestStore } from "@/store/god-ape-quest/god-ape-quest";
import { useMethBankStore } from "@/store/meth-bank";
import { useWalletCollectionStore } from "@/store/wallet-collection";
import { useWeb3Store } from "@/store/web3";
import { burnApes } from "../../burnApes";

export const forgeTraitOffchain = async (combinationId, ingredients) => {
  try {
    const ingredientsWithApes = ingredients.filter(
      (ingredient) => ingredient.type === FormulasIngredientType.APE
    );
    const ingredientsWithTeens = ingredients.filter(
      (ingredient) => ingredient.type === FormulasIngredientType.TEEN
    );

    if (ingredientsWithApes.length > 0) {
      await burnApes(ingredientsWithApes.map((ingredient) => ingredient.item));
    }
    if (ingredientsWithTeens.length > 0) {
      await burnTeens(ingredientsWithTeens.map((ingredient) => ingredient.item));
    }

    const signature = await signMessage("I'm combining traits");
    if (!signature) return;
    const traitsIn = ingredients
      .reduce((arr, ingredient) => {
        const item = ingredient.item;
        for (let i = 0; i < (item.count || 1); i++) {
          arr.push({ ...ingredient, item });
        }
        return arr;
      }, [])
      .map((ingredient) => ({ id: ingredient.item.id, type: ingredient.type }));

    const res = await useApesStorageApi({
      throw: true,
      toast: { error: true },
    }).exec({
      url: "/forge/trait",
      method: "POST",
      data: {
        wallet: useWeb3Store().collectionWallet,
        traitsIn,
        combinationId,
        signature,
        network: Config.network.name,
      },
    });
    useMethBankStore().fetchMethBank();
    useWalletCollectionStore().fetchApeTraits();
    useWalletCollectionStore().fetchCollection();
    useGodApeQuestStore().init(true);
    if (res.transactionInfo) {
      // mint god ape
      await mintGodApe(res.transactionInfo);
      return;
    }

    useModal().open({
      name: ModalName.FormulasPrizeModal,
      props: { type: "trait", item: res.traitMinted },
    });
    // god ape quest check
    if (res.godApePhaseCompleted) {
      const { bus } = useModal();
      const onClose = () => {
        bus.off("close", onClose);
        setTimeout(() => {
          useModal().open({
            name: ModalName.GodApeQuestFinishModal,
            props: { phase: res.godApePhaseCompleted },
          });
        }, 500);
      };
      bus.on("close", onClose);
    }
    return res;
  } catch (err) {
    captureError(err);
  }
};
