<template>
    <div class="formulas-ingredients">
        <div class="formulas-ingredients-title">Required ingredients</div>
        <div class="formulas-ingredients-row formulas-ingredients-balance-row">
            <div
                class="formulas-ingredients__item"
                v-for="(item, idx) in balanceIngredients"
                :key="idx"
            >
                <FormulasIngredient v-bind="item" />
                <IPlus class="formulas-ingredients__item-plus" width="30" />
            </div>
        </div>
        <div class="formulas-ingredients-row">
            <div class="formulas-ingredients__item" v-for="(item, idx) in ingredients" :key="idx">
                <FormulasIngredient v-bind="item" />
                <IPlus class="formulas-ingredients__item-plus" width="30" />
            </div>
        </div>
    </div>
</template>
<script setup>
import { useFormulasStore } from "@/store/formulas/formulas";
import { computed } from "vue-demi";
import FormulasIngredient from "../FormulasIngredient/FormulasIngredient.vue";
import IPlus from "@/assets/icons/plus-stroke.svg?inline"
import { FormulasIngredientType } from "@/helpers/formulas/formulas-ingredient-types";
const formulasStore = useFormulasStore()
const ingredients = computed(() => {
    return formulasStore.formulaIngredients
        .filter(item => ![FormulasIngredientType.METH, FormulasIngredientType.FORGE_BLOCK].includes(item.type))
        .map((item) => ({ ...item, isSatisfy: formulasStore.ingredientsSatisfyMap[item.idx] }))
})
const balanceIngredients = computed(() => {
    return formulasStore.formulaIngredients
        .filter(item => [FormulasIngredientType.METH, FormulasIngredientType.FORGE_BLOCK].includes(item.type))
        .map((item) => ({ ...item, isSatisfy: formulasStore.ingredientsSatisfyMap[item.idx] }))

})
</script>
<style lang="scss">
.formulas-ingredients {
    margin-top: 30px;
    &-title {
        text-transform: uppercase;
        opacity: 0.5;
        font-weight: bold;
        text-align: center;
        margin-bottom: 15px;
        background: var(--border);
        padding: 5px;
    }
    &-row {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        margin: -15px 0;
        margin-right: -30px;
    }
    &-balance-row {
        margin-bottom: 10px;
    }
    &-balance-row & {
    }
    &__item {
        margin: 15px 0;
        width: calc(100% / 3);
        display: flex;
        align-items: center;
        .formulas-ingredient {
            flex: 1;
        }
        &-plus {
            content: "";
            display: block;
            width: 20px;
            height: 20px;
            flex-shrink: 0;
            margin: 0 10px;
            opacity: 0.5;
        }
        &:nth-child(3n) & {
            &-plus {
                opacity: 0;
            }
        }
        &:last-child & {
            &-plus {
                opacity: 0;
            }
        }
    }
    @media screen and (max-width: 860px) {
        &-row {
            flex-direction: column;
            margin: 0;
        }
        &__item {
            flex-direction: column;
            margin: 0;
            margin-bottom: 10px;
            width: 100%;
            &-plus {
                margin: 15px 0;
                opacity: 1 !important;
            }
            &:last-child & {
                &-plus {
                    opacity: 0 !important;
                }
            }
        }
    }
}
</style>