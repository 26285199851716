<template>
    <div
        class="formulas-formula-card ape-trait has-rarity"
        :class="{
            active: isSelected,
            [`rarity-${rarity}`]: true,
            [`team-${item.team_type}`]: !!isQuestFormula, hidden: isHidden, blurred: isBlurred, ['active-team']: isSameTeam,
            'sold-out': isSoldOut,
            'has-ingredients': hasAllIngredients && !isSoldOut
        }"
    >
        <div class="ape-trait__image formulas-formula-card__image">
            <FormulasFormulaCardText :item="item" v-if="isBlurred" />
            <img :src="image" width="300" height="300" alt="formulas-image" @contextmenu.prevent />
            <div class="formulas-formula-card__sold-out" v-if="isSoldOut">Sold Out</div>
            <div class="formulas-formula-card__coming-soon" v-else-if="isComingSoon">
                Coming Soon
                <div class="formulas-formula-card__coming-soon-timer">
                    <template v-if="comingSoonTimer.days.value > 0">{{ comingSoonTimer.days }}d</template>
                    {{ comingSoonTimer.hours }}h {{ comingSoonTimer.minutes }}m {{ comingSoonTimer.seconds }}s
                </div>
            </div>
        </div>
        <div
            class="formulas-formula-card__team"
            v-tooltip="{ content: 'This is God Ape quest formula', distance: 10 }"
            v-if="isQuestFormula"
        >
            <span>
                <ISwords width="12" />
                {{ teamName }}
            </span>
        </div>
        <div class="ape-trait__counts">
            <div
                v-if="traitInWallet.offChain >= 1"
                v-tooltip="'You have ' + traitInWallet.offChain + ' off-chain trait' + (item.offChain > 1 ? 's' : '')"
                class="ape-trait__count ape-trait__count-offchain"
            >
                <IOffChain width="14" />
                {{ traitInWallet.offChain }}
            </div>
            <div
                v-if="traitInWallet.balance >= 1"
                v-tooltip="'You have ' + traitInWallet.balance + ' on-chain trait' + (traitInWallet.balance > 1 ? 's' : '')"
                class="ape-trait__count ape-trait__count-onchain"
            >
                <IWallet width="14" />
                {{ traitInWallet.balance }}
            </div>
        </div>
        <div class="ape-trait__name">{{ name }}</div>
        <div class="ape-trait__param">
            <span>Minted Count</span>
            {{ mintedCount }}
        </div>
        <div class="ape-trait__rarity" :class="rarity">{{ rarityName }}</div>
    </div>
</template>
<script setup>
import { resizeImageCF } from "@/helpers/resize-image-cloudflare";
import { useFormulasStore } from "@/store/formulas/formulas";
import { useWalletCollectionStore } from "@/store/wallet-collection";
import { computed } from "vue-demi";
import IWallet from "@/assets/icons/ape-constructor/ape-constructor-wallet.svg?inline"
import IOffChain from "@/assets/icons/off-chain.svg?inline"
import ISwords from "@/assets/icons/swords.svg?inline"
import { useGodApeQuestStore } from "@/store/god-ape-quest/god-ape-quest";
import { useTimer } from "vue-timer-hook";
import FormulasFormulaCardText from "./FormulasFormulaCardText.vue";
import { isSatisfyIngredient } from "@/helpers/formulas/formulas-satisfy-ingredient";
import { formulasBuildFormulaIngredients } from "@/helpers/formulas/formulas-build-ingredients";
import { FormulasIngredientType } from "@/helpers/formulas/formulas-ingredient-types";
const props = defineProps({
    item: {
        type: Object,
        default: () => ({})
    }
})
const formulasStore = useFormulasStore()
const currentTrait = computed(() => props.item.trait_out)
const image = computed(() => resizeImageCF(currentTrait.value.image, 512))
const name = computed(() => currentTrait.value.name)
const mintedCount = computed(() => currentTrait.value.total_minted)

const traitInWallet = computed(() => {
    if (currentTrait.value.type === 'utility') {
        const utility = useWalletCollectionStore().utilities.find(item => item.id === currentTrait.value.id)
        return { offChain: utility?.offChainCount || 0, balance: utility?.balance || 0 }
    }
    return useWalletCollectionStore().apeTraits.find(trait => trait.id == currentTrait.value.id) || { offChain: 0, balance: 0 }
})
const isSelected = computed(() => formulasStore.activeFormulaId == props.item.combination_id)
const rarity = computed(() => currentTrait.value.rarity)
const rarityName = computed(() => rarity.value ? rarity.value.charAt(0).toUpperCase() + rarity.value.slice(1) : '')
const isQuestFormula = computed(() => !!props.item.team_type)
const teamName = computed(() => props.item.team_type === 'all' ? 'Both' : props.item.team_type[0]?.toUpperCase() + props.item.team_type?.slice(1))

// const isBlurred = computed(() => isQuestFormula.value && traitInWallet.value.offChain < 1 && traitInWallet.value.balance < 1)
// don't blur in phase 2
const isBlurred = computed(() => {
    if(props.item.phase > 6) return false
    return props.item.phase >= useGodApeQuestStore().currentPhase && !useGodApeQuestStore().completedCombinations.includes(props.item.combination_id) && traitInWallet.value.offChain < 1 && traitInWallet.value.balance < 1
})

const isSameTeam = computed(() => isQuestFormula.value && useGodApeQuestStore().team === props.item.team_type)
const isSoldOut = computed(() => props.item.is_minted_out)
const comingSoonTimer = useTimer(new Date(props.item.launchDate).getTime(), true)
const isComingSoon = computed(() => props.item.comingSoon && !comingSoonTimer.isExpired.value)

const hasAllIngredients = computed(() => {
    // it doesn't count `selectable` ingredients since they are hard to track
    const ingredients = formulasBuildFormulaIngredients(props.item)
    const satisfyMap = ingredients.filter(
        (ingredient) => ingredient.type !== FormulasIngredientType.FORGE_BLOCK && !formulasStore.isSelectable(ingredient.type)
    )
        .map((ingredient, idx) => isSatisfyIngredient(ingredient, idx));
    const hasAllIngredients = !satisfyMap.includes(false);
    return hasAllIngredients
})

const isHidden = computed(() => {

    if (isQuestFormula.value) {
        if(props.item.phase > 6) return false
        const allowWithTeam = props.item.team_type === 'all' || useGodApeQuestStore().team === props.item.team_type
        const allowWithPhase = props.item.phase < useGodApeQuestStore().currentPhase
        return !allowWithTeam && !allowWithPhase
    }
    return false
})
</script>
<style lang="scss">
.formulas-formula-card {
    border: 2px solid var(--border);
    cursor: pointer;
    position: relative;
    &.ape-trait {
        box-shadow: none;
    }
    &__team {
        position: absolute;
        top: -7px;
        left: -5px;
        font-size: 14px;

        box-sizing: border-box;
        span {
            position: relative;
            z-index: 2;
            display: flex;
            padding: 0px 5px;

            align-items: center;
        }
        &::before {
            content: "";
            transform: skew(-15deg);
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 0;
            border-radius: 3px;
        }
        svg {
            margin-right: 4px;
        }
    }
    &__image {
        position: relative;
    }
    &__coming-soon {
        flex-direction: column;
        &-timer {
            opacity: 0.7;
            text-transform: none;
            margin-top: 5px;
        }
    }
    &__image-text {
        position: absolute;
        z-index: 1;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        span {
            font-size: 0.9em;
        }
    }
    &__sold-out,
    &__coming-soon {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba(0, 0, 0, 0.7);
        text-transform: uppercase;
        font-weight: bold;
    }
    &.active {
        border: 2px solid var(--primary) !important;
    }
    &.hidden {
        pointer-events: none;
        > div:not(.formulas-formula-card__team) {
            filter: blur(8px);
        }
    }
    &.blurred {
        .ape-trait__image img,
        .ape-trait__name {
            filter: blur(8px);
        }
    }
    &.team-ava &, &.team-good & {
        &__team {
            &::before {
                background: var(--ape-quest-ava);
            }
        }
    }
    &.team-vyl &, &.team-evil & {
        &__team {
            &::before {
                background: var(--ape-quest-vyl);
            }
        }
    }
    &.team-all & {
        &__team {
            &::before {
                background: linear-gradient(
                    to top,
                    var(--ape-quest-ava) 50%,
                    var(--ape-quest-ava) 53%,
                    var(--ape-quest-vyl) 54%,
                    var(--ape-quest-vyl)
                );
            }
        }
    }
    &.active-team.team-vyl {
        border-color: var(--ape-quest-vyl);
    }
    &.active-team.team-ava {
        border-color: var(--ape-quest-ava);
    }

    &.sold-out {
    }
    &.has-ingredients {
        animation: pulse 2.5s ease-in-out infinite;
    }
}
@keyframes pulse {
    0% {
        box-shadow: 0 0 3px 0 var(--yellow);
    }
    50% {
        box-shadow: 0 0 10px 0 var(--yellow);
    }
    100% {
        box-shadow: 0 0 3px 0 var(--yellow);
    }
}
</style>