<template>
    <div class="formulas">
        <div class="formulas-container">
            <FormulasTopActions @back="goBack" @burn="burnTraits" />
            <div class="formulas-row" v-if="mode === 'default'">
                <div class="formulas-content">
                    <FormulasHeader />
                    <FormulasActions @burn="burnTraits" @mintGodApe="onMintGodApe" />
                    <FormulasTabs
                        :items="tabs"
                        :active-tab="activeTab"
                        @update:activeTab="selectTab"
                    />
                    <!-- <div class="formulas-content-title">Formulas</div> -->
                    <FormulasQuestInfo v-if="activeTab === 'god_ape_quest' && hasTeam" />

                    <FormulasStartQuestButton v-if="activeTab === 'god_ape_quest' && !hasTeam" />
                    <FormulasItems :tab="activeTab" v-else />
                </div>
                <FormulasPreview />
            </div>
            <FormulasBurn @back="goBack" :is-loading="isLoading" v-if="mode === 'burn'" />
        </div>
    </div>
</template>
<script setup>
import FormulasTopActions from "@/components/Formulas/FormulasTopActions.vue";
import FormulasHeader from "@/components/Formulas/FormulasHeader.vue";
import { usePageWithWallet } from "@/composables/usePageWithWallet";
import { useMethBankStore } from "@/store/meth-bank";
import { useWalletCollectionStore } from "@/store/wallet-collection";
import FormulasPreview from "@/components/Formulas/FormulasPreview/FormulasPreview.vue";
import FormulasItems from "@/components/Formulas/FormulasItems/FormulasItems.vue";
import FormulasActions from "@/components/Formulas/FormulasActions/FormulasActions.vue";
import { computed, ref } from "vue-demi";
import FormulasBurn from "@/components/Formulas/FormulasBurn/FormulasBurn.vue";
import { useRouter } from "vue-router";
import { useFormulasStore } from "@/store/formulas/formulas";
import FormulasTabs from "@/components/Formulas/FormulasTabs/FormulasTabs.vue";
import { useGodApeQuestStore } from "@/store/god-ape-quest/god-ape-quest";
import FormulasStartQuestButton from "@/components/Formulas/FormulasStartQuestButton/FormulasStartQuestButton.vue";
import { useModal } from "@/composables/useModal";
import { ModalName } from "@/components/Modal/modal-name";
import FormulasQuestInfo from "@/components/Formulas/FormulasQuestInfo/FormulasQuestInfo.vue";
import { mintGodApe } from "@/functions/god-ape/mint-god-ape";

const mode = ref('default')
const router = useRouter()
const isLoading = ref(false)
const selectedTab = ref('')
const formulasStore = useFormulasStore()
const tabs = [
    {
        name: "All Formulas",
        value: 'all'
    },
    {
        name: "God Ape Quest",
        value: 'god_ape_quest',
        image: require('@/assets/img/god-ape-quest/god-ape-quest-icon.svg')
    }
]
const activeTab = computed(() => {
    if (selectedTab.value) return selectedTab.value
    const tab = router.currentRoute.value.hash.slice(1)
    const isValid = tabs.map(t => t.value).includes(tab)
    if (isValid) {
        return tab
    }
    return 'all'
})
const goBack = () => {
    if (mode.value !== 'default') {
        mode.value = 'default'
        return
    }
    const lastPath = router.options.history.state.back
    if (lastPath) {
        router.back()
    } else {
        router.push({ name: "Collection" })
    }
}
const { isConnected, connect } = usePageWithWallet({
    async onInit(connected) {
        if (connected) {
            isLoading.value = true
            await Promise.all([
                useMethBankStore().fetchMethBank(),
                useWalletCollectionStore().fetchCollection(),
                useWalletCollectionStore().fetchApeTraits(),
                useFormulasStore().fetchFormulas(),
                useGodApeQuestStore().init()
            ])
            isLoading.value = false
        }
    },
    onWalletChange: async (isConnected) => {
        if (isConnected) {
            isLoading.value = true
            await Promise.all([
                useMethBankStore().fetchMethBank(),
                useWalletCollectionStore().fetchCollection(),
                useWalletCollectionStore().fetchApeTraits(),
                useFormulasStore().fetchFormulas(),
                useGodApeQuestStore().init(true)
            ])
            isLoading.value = false

        }

    }
})

const burnTraits = () => {
    mode.value = 'burn'
}
const selectTab = (tab) => {
    selectedTab.value = tab
    formulasStore.selectFormula(null)
}
const mintingApe = ref(false)
const onMintGodApe = async () => {
    // backup method to mint god ape
    if (mintingApe.value) return
    const godApeQuestStore = useGodApeQuestStore()
    mintingApe.value = true
    await mintGodApe(godApeQuestStore.godApeMintSignatures[0])
    mintingApe.value = false
}

const hasTeam = computed(() => useGodApeQuestStore().hasTeam)
</script>
<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,700;1,700&family=Oxanium:wght@500;700;800&display=swap");

.formulas {
    font-family: var(--font-oxanium);
    font-weight: bold;
    padding: 20px 40px;
    box-sizing: border-box;
    background-color: #000;
    flex: 1;
    overflow: hidden;
    &-container {
        max-width: 1400px;
        margin: 0 auto;
    }
    &-row {
        display: flex;
    }
    &-content {
        max-width: 650px;
        &-title {
            margin-bottom: 10px;
            text-transform: uppercase;
            font-size: 18px;
        }
    }
    &-preview {
        flex: 1;
        margin-left: 40px;
    }
    @media screen and (max-width: 1200px) {
        &-row {
            flex-direction: column;
        }
        &-preview {
            margin-left: 0;
        }
        &-content {
            margin-bottom: 40px;
            padding-bottom: 40px;
            max-width: 100%;
            border-bottom: 1px solid var(--border);
        }
    }
    @media screen and (max-width: 576px) {
        padding: 0 15px;
        padding-top: 20px;
    }
}
</style>